import { useState } from "react";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import fetch from 'isomorphic-fetch';
import API_BASE from '../constant';
import {reactLocalStorage} from 'reactjs-localstorage';
import  { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import helpers from '../../helper/helpers';
import { confirmAlert } from 'react-confirm-alert';

//Import Images
import logo from '../../assets/images/logo.svg';
import qr_code from '../../assets/images/qr-code.png';
import profile from '../../assets/images/profile.jpeg';
import edit from '../../assets/images/edit.png';
import Linkedin from '../../assets/images/Linkedin.svg';
import next from '../../assets/images/next.png';
import cell_phone from '../../assets/images/cell_phone.png';
import email from '../../assets/images/email.png';
import website from '../../assets/images/website.png';
import location from '../../assets/images/location.png';




class Edit_Bio extends Component {
	constructor(props){
	
       super(props);
       this.state = {
           fields: {},
           errors: {},
           succMsg: {},
           data: [],
           dynamiclass: ['head_err_msg'],
           succClass: ['head_err_msg'],
		}
		
		if(!helpers.check_login()){
			this.props.history.push('/auth/login')
		}else{
			this.get_video_details();
		}
		
		
		
		
    }
    
    get_video_details() {
    	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		let fields = this.state.fields;
		let type = 'get-video' ;
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "user_id="+user_id
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			
			if (res.status == 'True') {
			console.log(res.data);
				 fields['source'] = res.data.video_type; 
				 fields['link'] = res.data.video_url; 
				 fields['desc'] = res.data.video_desc;   
				console.log(res.data.bio)
				 this.setState({fields});
				
				
			}else {
				let errors={};
				errors['err']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           		this.setState({ dynamiclass });
           		
			}
		})
		return false;
	}

    handleValidation(e){
		
		
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Bio
        if(!fields["source"]){
           formIsValid = false;
           errors["source"] = "Video Source cannot be empty!";
        }
        if(!fields["link"]){
           formIsValid = false;
           errors["link"] = "Video Link cannot be empty!";
        }
        if(!fields["desc"]){
           formIsValid = false;
           errors["desc"] = "Video Description cannot be empty!";
        }
        

       this.setState({errors: errors});
       return formIsValid;
	}

	comfirm_delete = () => {
		
		confirmAlert({
		  //title: 'Confirm to submit',
		  message: 'Are you sure you want to remove this video?',
		  buttons: [
			{
				
			  label: 'Yes',
			  onClick: () => this.deleteVideo()
			},
			{
			  label: 'No',
			  //onClick: () => 
			}
		  ]
		})
	  };
	
	deleteVideo(){
		let type = 'add-video' ;
		let LOGIN_API = API_BASE.concat(type);
    	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "delete=yes&user_id="+user_id+""
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			
			if (res.status == 'True') {
				let succMsg={};
				succMsg['msg']=res.msg;
				this.setState({succMsg: succMsg});
				const succClass = 'alert alert-success is_error';
         	 	this.setState({ succClass })
         	 	window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
				});
				setTimeout(() => {
				  this.props.history.push('/my-profile')
				}, 2000); 
			}else {
				let errors={};
				errors['link']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           		this.setState({ dynamiclass });
           		
			}
		})
    
  	}
  

	video(e) {
		
		let fields = this.state.fields;
		let type = 'add-video' ;
		let LOGIN_API = API_BASE.concat(type);
    	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "video_type="+fields.source+"&video_url="+fields.link+"&video_desc="+fields.desc+"&user_id="+user_id+""
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			
			if (res.status == 'True') {
				let succMsg={};
				succMsg['msg']=res.msg;
				this.setState({succMsg: succMsg});
				const succClass = 'alert alert-success is_error';
         	 	this.setState({ succClass })
         	 	window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
				});
				setTimeout(() => {
				  this.props.history.push('/my-profile')
				}, 2000); 
			}else {
				let errors={};
				errors['link']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           		this.setState({ dynamiclass });
           		
			}
		})
	}
	
	videoSubmit(e){
        e.preventDefault();
        if(this.handleValidation(e)){
        	const dynamiclass = 'head_err_msg';
         	 this.setState({ dynamiclass })
	        this.video(e);
	    }else{
	      const dynamiclass = 'alert alert-danger is_error';
          this.setState({ dynamiclass });
	    }

    }

    handleChange(field, e){         
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
    }
	
	
	
	
  render() {
	
		
	
	const getYear = new Date().getFullYear();
    return (
    	<div className="profile-screen">
	<div className="container">
		<header className="header">
			<div className="row">
				<div className="menu col-sm-12">
					<div className="menu-items">
						<Link to="/my-profile"><span className="icofont-arrow-left"></span></Link>
					</div>
				</div>
				
			</div>
		</header>
		<div className="main-content video-sec">
			<div className="col-xs-12 name-hrdr">
				<h2>Edit video<span className="orange">.</span></h2>
			</div>
			
				<div className="infob-section social-edit-sec">
					<form method="post" onSubmit= {this.videoSubmit.bind(this)}>
					<div className={this.state.succClass}>
						<ul>       
		           	 <li> {this.state.succMsg["msg"]} </li>
		                     
	            	</ul>
            	</div> 
					<div className="form">
						<div className="field-hdng">
							<span className="left">Social network</span>
							<span className="right"><button type="button" className="button css-gocinq" data-testid="delete-button"><span onClick={this.comfirm_delete.bind(this,'')} className="text">Delete</span></button></span>
						</div>
						<div className="field">
							<span className="label">Video Source</span>
							<select  value={this.state.fields['source']} onChange={this.handleChange.bind(this, "source")} name="source">
								<option>Select Source</option>
								<option value="Youtube">Youtube</option>
								<option value="Vimeo">Vimeo</option>
							</select>
							<div className='invalid-feedback text-start d-block'>
                             	{this.state.errors["source"]}         
                        	</div>
						</div>
						<div className="field">
							<span className="label">Video link</span>
							<input type="text" onChange={this.handleChange.bind(this, "link")} value={this.state.fields['link']} name="link"/>
							<div className='invalid-feedback text-start d-block'>
                             	{this.state.errors["link"]}         
                        	</div>
						</div>
						<div className="field">
							<input type="text" onChange={this.handleChange.bind(this, "desc")} value={this.state.fields['desc']} placeholder="Video Description" name="test"/>
							<div className='invalid-feedback text-start d-block'>
                             	{this.state.errors["desc"]}         
                        	</div>
						</div>
					</div>
					<div className="button-sec">
						<div className="action">
							<button type="submit" className="stap-button" data-testid="profile-connections-button">Save</button>
						</div>
					</div>
				</form>
				</div>
		</div>
	</div>
</div>


    );
  }
}

export default Edit_Bio;
