import { useState } from "react";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import fetch from 'isomorphic-fetch';
import API_BASE from '../constant';
import {reactLocalStorage} from 'reactjs-localstorage';
import  { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import helpers from '../../helper/helpers';

//Import Images
import logo from '../../assets/images/logo.svg';
import qr_code from '../../assets/images/qr-code.png';
import profile from '../../assets/images/profile.jpeg';
import edit from '../../assets/images/edit.png';
import Linkedin from '../../assets/images/Linkedin.svg';
import next from '../../assets/images/next.png';
import cell_phone from '../../assets/images/cell_phone.png';
import email from '../../assets/images/email.png';
import website from '../../assets/images/website.png';
import location from '../../assets/images/location.png';



class share_info extends Component {
	constructor(props){
	
       super(props);
       this.state = {
           fields: {},
           errors: {},
           data: [],
           dynamiclass: ['accordion-content head_err_msg'],
           dynamiclass2: ['accordion-content2 head_err_msg'],
           selectimg:['0'],
           succMsg: {},
    	   succClass: ['head_err_msg'],
    	   Durl: ['#'],
		}
		
		if(!helpers.check_login()){
			this.props.history.push('/auth/login')
		}else{
			this.get_user_details();
		}
		
		
    }
    
    get_user_details() {
    	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		let fields = this.state.fields;
		let type = 'get-user-data' ;
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "user_id="+user_id
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			
			if (res.status == 'True') {
				 fields['profile_link'] = res.data.profile_link;  
				 this.setState({fields});
				
				
			}else {
				let errors={};
				errors['err']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           		this.setState({ dynamiclass });
           		
			}
		})
		return false;
	}

    handleValidation(e){
		
		
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Bio
        if(!fields["bio"]){
           formIsValid = false;
           errors["bio"] = "Bio cannot be empty!";
        }

       this.setState({errors: errors});
       return formIsValid;
	}

	bio(e) {
		
		let fields = this.state.fields;
		let type = 'edit-profile' ;
		let LOGIN_API = API_BASE.concat(type);
    	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "bio="+fields.bio+"&user_id="+user_id+""
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			
			if (res.status == 'True') {
				let succMsg={};
				succMsg['msg']=res.msg;
				this.setState({succMsg: succMsg});
				const succClass = 'alert alert-success is_error';
		 	 	this.setState({ succClass })
		 	 	window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
			}else {
				let errors={};
				errors['err']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           		this.setState({ dynamiclass });
           		window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
           		
			}
		})
	}
	
	bioSubmit(e){
        e.preventDefault();
        if(this.handleValidation(e)){
        	const dynamiclass = 'head_err_msg';
         	 this.setState({ dynamiclass })
	        this.bio(e);
	    }else{
	      //const dynamiclass = 'alert alert-danger is_error';
          //this.setState({ dynamiclass });
	    }

    }

    handleChange(field, e){         
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
    }
    copyToClipboard = e => {
    	alert("Link is copied");
    	let profile_link = this.state.fields.profile_link
    	 navigator.clipboard.writeText("https://testapp.smarttap.world/"+profile_link);
  	};
	openQRcode = e => {
		let dynamiclass = this.state.dynamiclass;
		if (dynamiclass == 'accordion-content head_err_msg') {
			const dynamiclass = 'accordion-content';
    		this.setState({ dynamiclass });
		}else{
			const dynamiclass = 'accordion-content head_err_msg';
    		this.setState({ dynamiclass });
		}

	}
	openbackground = e => {
		let dynamiclass2 = this.state.dynamiclass2;
		if (dynamiclass2 == 'accordion-content2 head_err_msg') {
			const dynamiclass2 = 'accordion-content2';
    		this.setState({ dynamiclass2 });
		}else{
			const dynamiclass2 = 'accordion-content2 head_err_msg';
    		this.setState({ dynamiclass2 });
		}

	}
	selectimg= e =>{
		let selectimg = this.state.selectimg;
		selectimg = e.target.id;  
		this.setState({selectimg});
	}
	sendEmail= e =>{
		let selectimg = this.state.selectimg;
		let Durl = this.state.Durl;
		let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		if(selectimg==0){
			alert("Please Select the image first!")
		}else{
			let Durl= 'https://dev.smarttap.world/virtual-background/?img='+selectimg+'&user_id='+user_id;
			this.setState({Durl});
		}
	}
	
	
  render() {
  	const new_qrcode = "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://testapp.smarttap.world/"+this.state.fields.profile_link
    return (
    	<div className="profile-screen">
	<div className="container">
		<header className="header">
			<div className="row">
				<div className="menu col-sm-12">
					<div className="menu-items">
						<Link to="/my-profile"><span className="icofont-arrow-left"></span></Link>
					</div>
				</div>
				
			</div>
		</header>
		<div className="main-content">
			<div className="col-xs-12 name-hrdr">
				<h2>More ways to share<span className="orange">.</span></h2>
			</div>
			<div className="col-xs-12  name-hrdr">
				<p>You can easily share your BOBCAT without the card itself.</p>
			</div>
			
			
			<div className="infob-section">
			        <div className="accordion-items">
			          <div className="accordion-title">
			            <div className="accordion-text">Profile</div>
			            <div className="right-icon"><span onClick={this.copyToClipboard.bind(this)} className="icofont-link"></span></div>
			          </div>
			        </div>
			        <div className="accordion-items">
			          <div className="accordion-title">
			            <div className="accordion-text">My QR code</div>
			            <div className="right-icon"><span onClick={this.openQRcode} className="icofont-qr-code"></span></div>
			          </div>
			          <div className={this.state.dynamiclass}>
			          	<div className="my-qr-code">
			          	<img src={new_qrcode}/>
			          	<p><span className="icofont-iphone">Scan Me</span></p>
			          	</div>
			          </div>
			        </div>
			        <div className="accordion-items">
			          <div className="accordion-title">
			            <div className="accordion-text">Virtual Background</div>
			            <div className="right-icon"><span onClick={this.openbackground} className="icofont-computer"></span></div>
			          </div>
			          <div className={this.state.dynamiclass2}>
			          <br></br>
			          	<h6>Make Connections Virtually!</h6>
			          	<p>Select your background and download the new BOBCAT Virtual Background that contains your unique QR code. People can simply scan your QR code, access your BOBCAT profile and save your contact.</p>
			          	<div className="my-backgroud">
			          		<a href="#1" onClick={this.selectimg.bind(1)}><img id="1" src="https://dev.smarttap.world/wp-content/uploads/2022/02/zoom1.png"/></a>
			          		<a href="#2" onClick={this.selectimg.bind(2)}><img id="2" src="https://dev.smarttap.world/wp-content/uploads/2022/02/zoom2.png"/></a>
			          		<a href="#3" onClick={this.selectimg.bind(3)}><img id="3" src="https://dev.smarttap.world/wp-content/uploads/2022/02/zoom3.png"/></a>
			          		<a href="#4" onClick={this.selectimg.bind(4)}><img id="4" src="https://dev.smarttap.world/wp-content/uploads/2022/02/zoom4.png"/></a>
			          		<a href="#5" onClick={this.selectimg.bind(5)}><img id="5" src="https://dev.smarttap.world/wp-content/uploads/2022/02/zoom5.png"/></a>
			          		<a href="#6" onClick={this.selectimg.bind(6)}><img id="6" src="https://dev.smarttap.world/wp-content/uploads/2022/02/zoom6.png"/></a>
			          		<a href="#7" onClick={this.selectimg.bind(7)}><img id="7" src="https://dev.smarttap.world/wp-content/uploads/2022/02/zoom7.png"/></a>
			          		<a href="#8" onClick={this.selectimg.bind(8)}><img id="8" src="https://dev.smarttap.world/wp-content/uploads/2022/02/zoom8.png"/></a>
			          	<div class="button-sec" onClick={this.sendEmail} ><div class="action">
			          		<a href={this.state.Durl}><button type="submit" class="stap-button" data-testid="profile-connections-button">Download</button></a></div></div>
			          	</div>
			          </div>
			        </div>
		           
			</div>
		</div>
		
	</div>
</div>


    );
  }
}

export default share_info;
