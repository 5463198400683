import { useState } from "react";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import logo from '../../assets/images/logo.svg';
import fetch from 'isomorphic-fetch';
import API_BASE from '../constant';
import {reactLocalStorage} from 'reactjs-localstorage';
import  { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
const queryString = require('query-string');


class Login extends Component {
	constructor(props){
	
       super(props);
       this.state = {
           fields: {},
           errors: {},
           data: [],
           dynamiclass: ['head_err_msg'],
           registerclass: ['container head_err_msg'],
           welcomeclass: ['welcome-screen'],
		}
		
		const values = queryString.parse(this.props.location.search);
		  if (typeof values.card_id !== "undefined") {
			  let fields =this.state.fields
			  fields['card_id'] = values.card_id;
			  this.setState({fields})
			}else{
				this.props.history.push('/auth/login')
			}
    }

    handleValidation(e){
		
		
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Name
        if(!fields["password"]){
           formIsValid = false;
           errors["password"] = "Password cannot be empty!";
        }

        //Email
        if(!fields["email"]){
           formIsValid = false;
           errors["email"] = "Email id cannot be empty!";
        }
        if(!fields["first_name"]){
           formIsValid = false;
           errors["first_name"] = "First Name cannot be empty!";
        }
        if(!fields["last_name"]){
           formIsValid = false;
           errors["last_name"] = "Last Name cannot be empty!";
        }
		
        if(e.target.email.value!=''){
           let lastAtPos = fields["email"].lastIndexOf('@');
           let lastDotPos = fields["email"].lastIndexOf('.');

           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
              formIsValid = false;
              errors["email"] = "Email is not valid!";
            }
		}  

       this.setState({errors: errors});
       return formIsValid;
	}

	register(e) {
		
		let fields = this.state.fields;
		let type = 'register' ;
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "card_id="+this.state.fields.card_id+"&first_name="+fields.first_name+"&last_name="+fields.last_name+"&email="+fields.email.trim()+"&password="+fields.password+"" 
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			
			if (res.status == 'True') {
				this.props.history.push('/auth/login')
			}else {
				let errors={};
				errors['err']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           		this.setState({ dynamiclass });
           	window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
           		
			}
		})
	}
	
	registerSubmit(e){

        e.preventDefault();
        if(this.handleValidation(e)){
        	const dynamiclass = 'head_err_msg';
         	 this.setState({ dynamiclass })
	        this.register(e);
	    }else{
	      //const dynamiclass = 'alert alert-danger is_error';
          //this.setState({ dynamiclass });
	    }

    }

    handleChange(field, e){         
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
    }

	closeWelcome(e){
		let welcomeclass = this.state.welcomeclass;
      welcomeclass = "welcome-screen head_err_msg";        
      this.setState({welcomeclass});
      let registerclass = this.state.registerclass;
      registerclass = "container";        
      this.setState({registerclass});
	}
	
	
	
  render() {
	
		
	
	const getYear = new Date().getFullYear();
    return (
    	
  <div className="login-screen">
  <div className={this.state.welcomeclass}>
			<div className="container">
				<div className="content">
					<header className="header">
						<h3>Welcome to BOBCAT</h3>
						<p>Your card is now ready to activate.</p>
					</header>
					<div className="button">
						<button type="button" onClick={this.closeWelcome.bind(this)} className="chakra-button" data-testid="welcome-to-stap-submit">Let's get started</button>
					</div>
				</div>
			</div>
		</div>
	<div className={this.state.registerclass}>
		<header className="header">
			<figure><img src={logo} alt="logo" /></figure>
		</header>
		<div className="main-content">
			<h2>Let’s create your account</h2>
			<p>Please fill the form you would like to create your BOBCAT account with.</p>
			<div className="login-sec">
				<form method="POST" onSubmit= {this.registerSubmit.bind(this)} >
				
					<div className={this.state.dynamiclass}>
						<ul>       
		                     <li>  {this.state.errors["err"]}   </li>
		                     
	                	</ul>
                    </div> 
               <div role="group" className="stap-form-control">
						
						<div className="input__group">
							<input type="text"
                                        className={`stap-input form-control`}
                                        id="first_name"
                                        name="first_name"
                                        onChange={this.handleChange.bind(this, "first_name")}
							    		value= {this.state.fields['first_name']}
                                        placeholder="First Name"
                                        
                                    />
							
						</div>
						 <div className='invalid-feedback text-start d-block'>
                             {this.state.errors["first_name"]}         
                    </div>
					</div>
					<div role="group" className="stap-form-control">
						
						<div className="input__group">
							<input type="text"
                                        className={`stap-input form-control`}
                                        id="last_name"
                                        name="last_name"
                                        onChange={this.handleChange.bind(this, "last_name")}
							    		value= {this.state.fields['last_name']}
                                        placeholder="Last Name"
                                        
                                    />
							
						</div>
						 <div className='invalid-feedback text-start d-block'>
                             {this.state.errors["last_name"]}         
                    </div>
					</div>
					<div role="group" className="stap-form-control">
						
						<div className="input__group">
							<input type="email"
                                        className={`stap-input form-control`}
                                        id="email"
                                        name="email"
                                        onChange={this.handleChange.bind(this, "email")}
							    		value= {this.state.fields['email']}
                                        placeholder="Email"
                                        
                                    />
							
						</div>
						 <div className='invalid-feedback text-start d-block'>
                             {this.state.errors["email"]}         
                    </div>
					</div>
					<div role="group" className="stap-form-control">
						
						<div className="input__group">
							 <input type='password'
                                            className={`stap-input form-control`}
                                            name="password"
                                            id="password"
                                            onChange={this.handleChange.bind(this, "password")}
							    			value={this.state.fields["password"]}
                                            placeholder="Password"
                                            
                                            
                                        />

                                   <span className="icofont-eye-altx"></span>
									<span className="icofont-eye-blockedx"></span>

                                       
						</div>
						<div className='invalid-feedback text-start d-block'>
                             {this.state.errors["password"]}         
                        </div>
					</div>
					<div className="submit-btn"><button type="submit" className="button" data-testid="login-button">Create Account</button></div>
				</form>
				<div className="noaccount"><span className="signup-link">No account? </span><a className="signup-button css-1m1uuvi" href="https://dev.smarttap.world/" target="_blank">Get your card</a></div>
				<div className="noaccount"><span className="signup-link">By clicking “Create Account” you agree to BOBCAT's </span><a className="signup-button css-1m1uuvi" href="#" target="_blank">Terms of Service</a> and <a className="signup-button css-1m1uuvi" href="#" target="_blank">Privacy Policy</a></div>
			</div>
		</div>
		
	</div>
</div>
    );
  }
}

export default Login;
