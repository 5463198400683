import { useState } from "react";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import fetch from 'isomorphic-fetch';
import API_BASE from '../constant';
import {reactLocalStorage} from 'reactjs-localstorage';
import  { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { confirmAlert } from 'react-confirm-alert';
import helpers from '../../helper/helpers';


//Import Images
import logo from '../../assets/images/logo.svg';
import qr_code from '../../assets/images/qr-code.png';
import profile from '../../assets/images/profile.jpeg';
import edit from '../../assets/images/edit.png';
import Linkedin from '../../assets/images/Linkedin.svg';
import next from '../../assets/images/next.png';
import cell_phone from '../../assets/images/cell_phone.png';
import email from '../../assets/images/email.png';
import website from '../../assets/images/website.png';
import location from '../../assets/images/location.png';




class Edit_Profile extends Component {
	constructor(props){
	
	  
       super(props);
       this.state = {
           fields: {},
           errors: {},
            succMsg: {},
           data: [],
           dynamiclass: ['head_err_msg'],
           succClass: ['head_err_msg'],
           selectedFile: null,
           profile_img: '',
           innerColor:["row profile"],
           outerColor:["profile-sec"],
           
		}
		if(!helpers.check_login()){
			this.props.history.push('/auth/login')
		}else{
			this.get_user_details();
		}
		
		
		
		
    }
    
    get_user_details() {
    	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		
		let fields = this.state.fields;
		let innerColor = this.state.innerColor;
		let outerColor = this.state.outerColor;
		let type = 'get-user-data' ;
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "user_id="+user_id
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			
			if (res.status == 'True') {
				 fields['first_name'] = res.data.first_name;
				 fields['last_name'] = res.data.last_name;   
				 fields['job_title'] = res.data.job_title;   
				 fields['company'] = res.data.company;   
				 fields['category'] = res.data.category;   
				 fields['profile_link'] = res.data.profile_link;   
				 fields['profile_pic'] = res.data.profile_pic;   
				 if (res.data.innerColor == '' || res.data.innerColor == null) {
				 	innerColor ="row profile";
				 }else{
				 	innerColor = res.data.innerColor; 
				 }
				 if (res.data.outerColor == '' || res.data.outerColor == null) {
				 	outerColor ="profile-sec";
				 }else{
				 	outerColor = res.data.outerColor; 
				 }
				      
				 
				 this.setState({profile_img:res.data.profile_pic});  
				 this.setState({fields});
				 this.setState({innerColor});
				 this.setState({outerColor});
				 //console.log(res.data.profile_pic)
				 
				
				
			}else {
				let errors={};
				errors['err']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           		this.setState({ dynamiclass });
           		
			}
		})
		return false;
	}
	
	comfirm_delete = () => {
		
		confirmAlert({
		  //title: 'Confirm to submit',
		  message: 'Are you sure you want to remove this profile image?',
		  buttons: [
			{
				
			  label: 'Yes',
			  onClick: () => this.delete_pic()
			},
			{
			  label: 'No',
			  //onClick: () => 
			}
		  ]
		})
	  };

	delete_pic(){
		let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id;
		let type = 'upload-image' ;
		const formData = new FormData();
		formData.append('user_id', user_id);
		formData.append('action', 'delete');
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			body : formData,
			method: 'POST',
			
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			
			if (res.status == 'True') {
				let succMsg={};
				succMsg['msg']=res.msg;
				this.setState({profile_img:res.data});  
				this.setState({succMsg: succMsg});
				const succClass = 'alert alert-success is_error';
         	this.setState({ succClass })
				const login_session = {
					isLogged: 1,
					user_id:sess_data.user_id,
					user_name:sess_data.username,
					email:sess_data.email,
					first_name:sess_data.first_name,
					last_name:sess_data.last_name,
					job_title:sess_data.job_title,
					company:sess_data.company,
					profile_pic:res.data,
				}
				window.sessionStorage.setItem('login_session', JSON.stringify(login_session));
				window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
				setTimeout(() => {
				  this.props.history.push('/my-profile')
				}, 2000);
				
			}else {
				let errors={};
				errors['err']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           		this.setState({ dynamiclass });
           	window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
           		
			}
		})
     
    
  	}
	
	onFileChange = event => {
      
		let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id;
		let type = 'upload-image' ;
		let selectedFile=event.target.files[0];
		//const formData = new FormData();
		//formData.append('profile_pic', selectedFile);
		const formData = new FormData();
		formData.append('user_id', user_id);
		formData.append('action', 'add');
		formData.append('profile_pic', selectedFile);
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			body : formData,
			method: 'POST',
			
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			
			if (res.status == 'True') {
				let succMsg={};
				succMsg['msg']=res.msg;
				//alert(res.image)
				this.setState({profile_img:res.data});  
				this.setState({succMsg: succMsg});
				const succClass = 'alert alert-success is_error';
         	this.setState({ succClass })
         	const login_session = {
					isLogged: 1,
					user_id:sess_data.user_id,
					user_name:sess_data.username,
					email:sess_data.email,
					first_name:sess_data.first_name,
					last_name:sess_data.last_name,
					job_title:sess_data.job_title,
					company:sess_data.company,
					profile_pic:res.data,
				}
				window.sessionStorage.setItem('login_session', JSON.stringify(login_session));
				window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
				setTimeout(() => {
				  this.props.history.push('/my-profile')
				}, 2000);
				
			}else {
				let errors={};
				errors['err']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           	this.setState({ dynamiclass });
           	window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
           		
			}
		})
      
      
      
    
    };

    handleValidation(e){
		
		
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Name
        if(!fields["first_name"]){
           formIsValid = false;
           errors["first_name"] = "First Name cannot be empty!";
        }

        //Email
        if(!fields["last_name"]){
           formIsValid = false;
           errors["last_name"] = "Last Name id cannot be empty!";
        }
        if(!fields["job_title"]){
           formIsValid = false;
           errors["job_title"] = "Job Title id cannot be empty!";
        }
        if(!fields["company"]){
           formIsValid = false;
           errors["company"] = "Company Name id cannot be empty!";
        }
        if(!fields["category"]){
           formIsValid = false;
           errors["category"] = "Please select category!";
        }
        if(!fields["profile_link"]){
           formIsValid = false;
           errors["profile_link"] = "Profile link id cannot be empty!";
        }
		
         

       this.setState({errors: errors});
       return formIsValid;
	}

	update_profile(e) {
		
		let fields = this.state.fields;
		let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id;
		let type = 'edit-profile' ;
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "first_name="+fields.first_name.trim()+
			"&last_name="+fields.last_name+
			"&job_title="+fields.job_title+
			"&company="+fields.company+
			"&category="+fields.category+
			"&profile_link="+fields.profile_link+
			"&user_id="+user_id+
			"&profile_pic="+this.staheselectedFile+
			"&innerColor="+this.state.innerColor+
			"&outerColor="+this.state.outerColor
			
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			
			if (res.status == 'True') {
				let succMsg={};
				succMsg['msg']=res.msg;
				this.setState({succMsg: succMsg});
				const succClass = 'alert alert-success is_error';
         	this.setState({ succClass })
				const login_session = {
					isLogged: 1,
					user_id:sess_data.user_id,
					user_name:sess_data.username,
					email:sess_data.email,
					first_name:fields.first_name,
					last_name:fields.last_name,
					job_title:fields.job_title,
					company:fields.company,
					profile_pic:sess_data.profile_pic,
					innerColor:this.state.innerColor,
					outerColor:this.state.outerColor,
				}
				window.sessionStorage.setItem('login_session', JSON.stringify(login_session));
				window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
				setTimeout(() => {
				  this.props.history.push('/my-profile')
				}, 2000);
				
			}else {
				let errors={};
				errors['profile_link']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           	this.setState({ dynamiclass });
           	window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
			}
		})
	}
	
	profileSubmit(e){

        e.preventDefault();
        if(this.handleValidation(e)){
        	const dynamiclass = 'head_err_msg';
         	 this.setState({ dynamiclass })
	        this.update_profile(e);
	    }else{
	      //const dynamiclass = 'alert alert-danger is_error';
          //this.setState({ dynamiclass });
	    }

    }

    handleChange(field, e){         
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
    }
	
	changeColor(color1,color2){
		let innerColor =this.state.innerColor;
		let outerColor =this.state.outerColor;
		innerColor = "row profile "+color1;
		outerColor = "profile-sec "+color2;
		this.setState({innerColor});
		this.setState({outerColor});
	}
	
	
  render() {
	
		
	
	const getYear = new Date().getFullYear();
    return (
    	
    	<div className="profile-screen">
    	<div ref={this.myRef}></div> 
	<div className="container">
		<header className="header">
			<div className="row">
				<div className="menu col-sm-12">
					<div className="menu-items">
						<Link to="/my-profile"><span className="icofont-arrow-left"></span></Link>
					</div>
				</div>
				
			</div>
		</header>
		<div className="main-content">
			<div className={this.state.succClass}>
		            		{this.state.succMsg["msg"]}
            		</div> 
			<div className="col-xs-12 name-hrdr">
				<h2>Edit card<span className="orange">.</span></h2>
			</div>
			
			<div className={this.state.outerColor} >
				<div className={this.state.innerColor}>
					<div className="col-md-4">
						<img src={this.state.profile_img} />
						<div className="upload-photo">
						<span className="icofont-camera">
						 <input type="file" onChange={this.onFileChange} />
						</span>
						</div>
					</div>
					<div className="rol-sec">
						<h1>
							{this.state.fields["first_name"]}<br/>
							{this.state.fields["last_name"]}
						</h1>
						{this.state.fields.company ? (
						<p className="rol">
							{this.state.fields.job_title} <br/>
							 at {this.state.fields.company}
							 						</p>
						):(<p className="rol">
							{this.state.fields.job_title}</p>)}
					</div>
					<span className="edit-optn remove"><span className="icofont-close-line" onClick={this.comfirm_delete.bind(this)}></span></span>
					
				</div>
				<div className="col-xs-12 design">
					<h2>Change your profile design</h2>
					<div className="color-design">
						<ul>
							<li className={this.state.innerColor === "row profile box1" ? "active" : ""} onClick={() => this.changeColor("box1","box11")}>
								<div className="upper-color" style={{background: "rgba(0, 0, 0, 0) linear-gradient(135deg, rgb(255, 92, 36) 10%, rgb(242, 10, 36) 70%, rgb(199, 18, 101) 100%) repeat scroll center center / cover"}}></div>
								<div className="center-number">1</div>
								<div className="bottem-color" style={{background: "rgb(113, 13, 107) none repeat scroll 0% 0% / cover"}}></div>
							</li>
							<li className={this.state.innerColor === "row profile box2" ? "active" : ""} onClick={() => this.changeColor("box2","box22")}>
								<div className="upper-color" style={{background: "rgba(0, 0, 0, 0) linear-gradient(135deg, rgb(247, 211, 242) 0%, rgb(219, 248, 254) 30%, rgb(198, 223, 251) 100%) repeat scroll center center / cover"}}></div>
								<div className="center-number">2</div>
								<div className="bottem-color" style={{background: "rgb(175, 181, 188) none repeat scroll 0% 0% / cover"}}></div>
							</li>
							<li className={this.state.innerColor === "row profile box3" ? "active" : ""} onClick={() => this.changeColor("box3","box33")}>
								<div className="upper-color" style={{background: "rgba(0, 0, 0, 0) linear-gradient(135deg, rgb(41, 109, 54) 0%, rgb(62, 176, 68) 100%) repeat scroll center center / cover"}}></div>
								<div className="center-number">3</div>
								<div className="bottem-color" style={{background: "rgb(50, 69, 57) none repeat scroll 0% 0% / cover"}}></div>
							</li>
							<li className={this.state.innerColor === "row profile box4" ? "active" : ""} onClick={() => this.changeColor("box4","box44")}>
								<div className="upper-color" style={{background: "rgb(1, 1, 1) none repeat scroll center center / cover"}}></div>
								<div className="center-number">4</div>
								<div className="bottem-color" style={{background: "rgb(255, 100, 58) none repeat scroll 0% 0% / cover"}}></div>
							</li>
							<li className={this.state.innerColor === "row profile box5" ? "active" : ""} onClick={() => this.changeColor("box5","box55")}>
								<div className="upper-color" style={{background: "rgb(35, 81, 164) none repeat scroll center center / cover"}}></div>
								<div className="center-number">5</div>
								<div className="bottem-color" style={{background: "rgb(16, 52, 116) none repeat scroll 0% 0% / cover"}}></div>
							</li>
							<li className={this.state.innerColor === "row profile box6" ? "active" : ""} onClick={() => this.changeColor("box6","box66")}>
								<div className="upper-color" style={{background: "rgb(255, 255, 255) none repeat scroll center center / cover"}}></div>
								<div className="center-number">6</div>
								<div className="bottem-color" style={{background: "rgb(0, 0, 0) none repeat scroll 0% 0% / cover"}}></div>
							</li>
							<li className={this.state.innerColor === "row profile box7" ? "active" : ""} style={{background: ""}} onClick={() => this.changeColor("box7","box77")}>
								<div className="upper-color" style={{background: "rgba(0, 0, 0, 0) linear-gradient(135deg, rgb(217, 220, 202) 0%, rgb(145, 203, 202) 100%) repeat scroll center center / cover"}}></div>
								<div className="center-number">7</div>
								<div className="bottem-color" style={{background: "rgb(147, 204, 202) none repeat scroll 0% 0% / cover"}}></div>
							</li>
						</ul>
					</div>
				</div>
				<form method="POST" onSubmit= {this.profileSubmit.bind(this)} >
				<div className="infof-section">
				
					
					
					<div className="form">
						<div className="field-sec">
							<span className="label">First name</span>
							<input type="text" 
							value= {this.state.fields['first_name']} 
							onChange={this.handleChange.bind(this, "first_name")}
							name="first_name"/>
							<div className='invalid-feedback text-start d-block'>
                             {this.state.errors["first_name"]}         
                        </div>
						</div>
						<div className="field-sec">
							<span className="label">Last name</span>
							<input type="text" 
							value= {this.state.fields['last_name']} 
							onChange={this.handleChange.bind(this, "last_name")}
							name="last_name"/>
							<div className='invalid-feedback text-start d-block'>
                             {this.state.errors["last_name"]}         
                        </div>
						</div>
						<div className="field-sec">
							<span className="label">Job title</span>
							<input type="text" 
							value= {this.state.fields['job_title']}
							onChange={this.handleChange.bind(this, "job_title")}  
							name="job_title"/>
							<div className='invalid-feedback text-start d-block'>
                             {this.state.errors["job_title"]}         
                        </div>
						</div>
						<div className="field-sec">
							<span className="label">Company</span>
							<input type="text" 
							value= {this.state.fields['company']}
							onChange={this.handleChange.bind(this, "company")}  
							name="company"/>
							<div className='invalid-feedback text-start d-block'>
                             {this.state.errors["company"]}         
                        </div>
						</div>
						<div className="field-sec">
							<span className="label">Industry or Category of work</span>
							<select onChange={this.handleChange.bind(this, "category")} value= {this.state.fields['category']}>
								<option >Select Category</option>
								<option value="Accounting" >Accounting</option>
                          <option value="Agriculture">Agriculture</option>
                          <option value="Arts">Arts</option>
                          <option value="Auto">Auto</option>
                          <option value="B2B Services">B2B Services</option>
                          <option value="Agriculture">Agriculture</option>
                          <option value="Construction">Construction</option>
                          <option value="Consulting">Consulting</option>
                          <option value="Cosmetics">Cosmetics</option>
                          <option value="E-Commerce">E-Commerce</option>
                          <option value="Education">Education</option>
                          <option value="">Entertainment</option>
                          <option value="Entertainment">Finance</option>
                          <option value="Food">Food</option>
                          <option value="Government">Government</option>
                          <option value="Healthcare">Healthcare</option>
                          <option value="Home Services">Home Services</option>
                          <option value="Hospitality">Hospitality</option>
                          <option value="Insurance">Insurance</option>
                          <option value="Law">Law</option>
                          <option value="Manufacturing">Manufacturing</option>
                          <option value="Marketing">Marketing</option>
                          <option value="Media">Media</option>
                          <option value="Mining">Mining</option>
                          <option value="Other">Other</option>
                          <option value="Real Estate">Real Estate</option>
                          <option value="Retail">Retail</option>
                          <option value="Science">Science</option>
                          <option value="Tech">Tech</option>
                          <option value="Utilities">Utilities</option>
                          <option value="Wholesale Trade">Wholesale Trade</option>
							</select>
							<div className='invalid-feedback text-start d-block'>
                             {this.state.errors["category"]}         
                        </div>
						</div>
					</div>
					<div className="url-sec">
						<div className="form">
							<h3 className="sec-hdng">Profile Link</h3>
							<span className="label">Your profile URL</span>
							<input type="text" 
							value= {this.state.fields['profile_link']} 
							onChange={this.handleChange.bind(this, "profile_link")}
							name="profile_link"/>
							<p>https://stap.digital/{this.state.fields['profile_link']}</p>
							<div className='invalid-feedback text-start d-block'>
                             {this.state.errors["profile_link"]}         
                        </div>
						</div>
					</div>
					<div className="button-sec">
					<div className="action">
						<button type="submit" className="stap-button" data-testid="profile-connections-button">Save</button>
					</div>
				</div>
				
				</div>
				</form>
				
			</div>
		</div>
		
	</div>
</div>

    );
  }
}

export default Edit_Profile;
