import { useState } from "react";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import fetch from 'isomorphic-fetch';
import API_BASE from '../constant';
import {reactLocalStorage} from 'reactjs-localstorage';
import  { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import helpers from '../../helper/helpers';

//Import Images
import logo from '../../assets/images/logo.svg';
import qr_code from '../../assets/images/qr-code.png';
import loaderimg from '../../assets/images/smarttap_logo_gif.gif';
import profile from '../../assets/images/profile.jpeg';
import edit from '../../assets/images/edit.png';
import Linkedin from '../../assets/images/Linkedin.svg';
import next from '../../assets/images/next.png';
import cell_phone from '../../assets/images/cell_phone.png';
import email from '../../assets/images/email.png';
import website from '../../assets/images/website.png';
import location from '../../assets/images/location.png';
import loader from '../../assets/images/loader.gif';




class Login extends Component {
	constructor(props){
	
       super(props);
       	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let innerColors ="row profile";
		let outerColors ="profile-sec";
		if (sess_data.innerColor) {
			innerColors = sess_data.innerColor; 
			 	 
		 }else{
		 	 innerColors ="row profile";
		 }
		 if (sess_data.outerColor) {
		 	outerColors = sess_data.outerColor; 
		 	 
		 }else{
		 	 outerColors ="profile-sec";
		 }
		 
       this.state = {
           fields: {},
           errors: {},
           data: [],
           dynamiclass: ['head_err_msg'],
           loader: ['loader'],
           dynamicshow: ['icofont-navigation-menu show'],
           mySidenav: ['sidenav'],
           contactinfo:{},
           phone_list:[],
           email_list:[],
           website_list:[],
           address_list:[],
           location_list:[],
           customLink:[],
           social_link:[],
           social_link_updated:[],
           social_count:0,
           social_network: [],
           innerColor:innerColors,
		   outerColor:outerColors
           
		

		}

		if(!helpers.check_login()){
			this.props.history.push('/auth/login')
		}else{
			
			let fields = this.state.fields;
			let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
			fields['bio'] = sess_data.bio;
			fields['first_name'] = sess_data.first_name;
			fields['email'] = sess_data.email;
			fields['last_name'] = sess_data.last_name;
			fields['job_title'] =sess_data.job_title;
			fields['company'] = sess_data.company;
			fields['profile_pic'] = sess_data.profile_pic;
			
			//this.State({innerColor});
			//this.setState({outerColor});
			this.get_user_details();
			
			
			let social_network = this.state.social_network;
			social_network['Select-Network']={key: 'Select_Network', value: 'Select Network',url:''}
			social_network['Anchor']={key: 'Anchor', value: 'Anchor',url:'https://anchor.fm/'}
			social_network['Apple_Podcast']={key: 'Apple_Podcast', value: 'Apple Podcast',url:'https://podcasts.apple.com/'}
			social_network['Calendly']={key: 'Calendly', value: 'Calendly',url:'https://calendly.com/'}
			social_network['Facebook']={key: 'Facebook', value: 'Facebook',url:'https://facebook.com/'}
			social_network['Github']={key: 'Github', value: 'Github',url:'https://github.com/'}
			social_network['Instagram']={key: 'Instagram', value: 'Instagram',url:'https://www.instagram.com/'}
			social_network['Linkedin']={key: 'Linkedin', value: 'Linkedin',url:'https://linkedin.com/in/'}
			social_network['Linkedin_company']={key: 'Linkedin_company', value: 'Linkedin company',url:'https://linkedin.com/company/'}
			social_network['Line']={key: 'Line', value: 'Line',url:'https://line.me/ti/p/'}
			social_network['Messenger']={key: 'Messenger', value: 'Messenger',url:'https://m.me/'}
			social_network['Metamask']={key: 'Metamask', value: 'Metamask',url:'https://metamask.app.link/send/'}
			social_network['Paypal']={key: 'Paypal', value: 'Paypal',url:'https://paypal.me/'}
			social_network['Snapchat']={key: 'Snapchat', value: 'Snapchat',url:'https://snapchat.com/add/'}
			social_network['Sound_Cloud']={key: 'Sound_Cloud', value: 'Sound Cloud',url:'https://soundcloud.com/'}
			social_network['Spotify']={key: 'Spotify', value: 'Spotify',url:'https://open.spotify.com/'}
			social_network['Tiktok']={key: 'Tiktok', value: 'Tiktok',url:'https://vm.tiktok.com/'}
			social_network['Twitch']={key: 'Twitch', value: 'Twitch',url:'https://www.twitch.tv/'}
			social_network['Twitter']={key: 'Twitter', value: 'Twitter',url:'https://twitter.com/'}
			social_network['Vimeo']={key: 'Vimeo', value: 'Vimeo',url:'https://venmo.com/'}
			social_network['Whatsapp']={key: 'Whatsapp', value: 'Whatsapp',url:'https://wa.me/'}
			social_network['Youtube']={key: 'Youtube', value: 'Youtube',url:'https://youtube.com/'}
			this.setState({social_network});
		}
			
    }

    get_user_details() {
    	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		let fields = this.state.fields;
		let innerColor = this.state.innerColor;
		let outerColor = this.state.outerColor;

        innerColor = sess_data.innerColor;
		outerColor = sess_data.outerColor;
		this.setState({innerColor});
		this.setState({outerColor});
		let type = 'profile-data' ;
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "user_id="+user_id
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			if (res.status == 'True') {
				 let loader = this.state.loader;
				 loader = "loader head_err_msg"
				 this.setState({loader});
				 fields['bio'] = res.data.profiledata.bio;
				 fields['first_name'] = res.data.profiledata.first_name;
				 fields['last_name'] = res.data.profiledata.last_name;
				 fields['job_title'] = res.data.profiledata.job_title;
				 fields['company'] = res.data.profiledata.company;
				 fields['profile_link'] = res.data.profiledata.profile_link;
				 fields['profile_pic'] = res.data.profiledata.profile_pic;
				 if (res.data.profiledata.innerColor == '' || res.data.profiledata.innerColor == null) {
				 	innerColor ="row profile";
				 }else{
				 	innerColor = res.data.profiledata.innerColor; 
				 }
				 if (res.data.profiledata.outerColor == '' || res.data.profiledata.outerColor == null) {
				 	outerColor ="profile-sec";
				 }else{
				 	outerColor = res.data.profiledata.outerColor; 
				 }
				 this.setState({innerColor});
				 this.setState({outerColor});
				 fields['social_link'] = res.data.social_link;
				 fields['customlink'] = res.data.customlink;
				 fields['video'] = res.data.video;
				 fields['contactinfo'] = res.data.contactinfo;
				 fields['views'] = res.data.analytics.views;
				 fields['exchanges'] = res.data.analytics.exchanges;
				 fields['save'] = res.data.analytics.save;
				 let customLink=this.state.customLink;
				 let social_link=this.state.social_link;
				 social_link=JSON.parse(res.data.social_link.social_link);
				 let social_count=this.state.social_count;
				 social_count=res.data.social_link.social_count;
				 customLink=res.data.customlink;
				 this.setState({customLink});
				 this.setState({social_link});
				 this.setState({social_count});
				  
				
     			 let updated_social_link=[];
				 let idx=0;
				 let social_network = this.state.social_network;
				 if(social_count){
		             for (var j = 0; j <= social_count; j++) {
	     	       		if(j!=i){
	     	       			let val1="social_media_"+j;
	     	       			let val2="social_text_"+j;
	     		        	let name=social_link[val1];
	     		        	let text_val=social_link[val2];
	     		        	let img="https://dev.smarttap.world/wp-content/uploads/2022/01/"+name+".png";
	     		        	let re_url=social_network[name].url+text_val;
	     		        	
	     		        	//alert(re_url)
	     		        	
	     		        	updated_social_link[j]={'img':img,'url':re_url}
	     		        	//console.log(this.state.social_link)
	     		        	 this.setState({social_link_updated:updated_social_link});
	     		        	
	        	        	idx++;
	     	        	}	
		 			}
		 		}
				  
				  
				 if(res.data.contactinfo.phone_count == 0 && res.data.contactinfo.email_count == 0 && res.data.contactinfo.website_count == 0 && res.data.contactinfo.location_count == 0){
				 	fields['contactinfo'] = '';
				 }
				 let contactinfo = this.state.contactinfo;
				 if(res.data.contactinfo.phone_data){
					  contactinfo['phone_data']=JSON.parse(res.data.contactinfo.phone_data);
				 }
				 if(res.data.contactinfo.email_data){
					  contactinfo['email_data']=JSON.parse(res.data.contactinfo.email_data);
				 }
				 if(res.data.contactinfo.website_data){
					  contactinfo['website_data']=JSON.parse(res.data.contactinfo.website_data);
				 }
				 if(res.data.contactinfo.location_data){
					  contactinfo['address_data']=JSON.parse(res.data.contactinfo.location_data);
				 }
				 this.setState({contactinfo});
				 let phone_list = this.state.phone_list
				 for (var i = 0; i < this.state.fields.contactinfo.phone_count; i++) {
			       let contact = this.state.contactinfo.phone_data['contact_'+i];
			       phone_list.push(contact);
			   	 } 
			     this.setState(phone_list);
			     let email_list = this.state.email_list
				 for (var i = 0; i < this.state.fields.contactinfo.email_count; i++) {
			       let email = this.state.contactinfo.email_data['email_'+i];
			       email_list.push(email);
			   	 } 
			     this.setState(email_list);

			     let website_list = this.state.website_list
				 for (var i = 0; i < this.state.fields.contactinfo.website_count; i++) {
			       let website = this.state.contactinfo.website_data['website_'+i];
			       website_list.push(website);
			   	 } 
			     this.setState(website_list);

			     let address_list = this.state.address_list
			     let location_list = this.state.location_list
				 for (var i = 0; i < this.state.fields.contactinfo.location_count; i++) {
				 	let area2 = this.state.contactinfo.address_data['area2_'+i];
				 	if (!this.state.contactinfo.address_data['area2_'+i]) {
				 		area2 = '';
				 	}else{
				 		area2 = this.state.contactinfo.address_data['area2_'+i]
				 	}
			        let location = this.state.contactinfo.address_data['address_type_'+i]+': '+this.state.contactinfo.address_data['area1_'+i]+' '+area2+' '+this.state.contactinfo.address_data['city_'+i]+' '+this.state.contactinfo.address_data['state_'+i]+' '+this.state.contactinfo.address_data['pcode_'+i]+' '+this.state.contactinfo.address_data['country_'+i];
			        let onlylocation = this.state.contactinfo.address_data['area1_'+i]+' '+area2+' '+this.state.contactinfo.address_data['city_'+i]+' '+this.state.contactinfo.address_data['state_'+i]+' '+this.state.contactinfo.address_data['pcode_'+i]+' '+this.state.contactinfo.address_data['country_'+i];
			    	location_list.push(onlylocation);
			        address_list.push(location);
			   	 } 
			     this.setState(location_list);
			     this.setState(address_list);
     	
			}
		})
		return false;
	}

	openNav(e){
		let mySidenav = this.state.mySidenav;
        mySidenav = "sidenav mySidenav";        
        this.setState({mySidenav});
	}
	closeNav(e){
		let mySidenav = this.state.mySidenav;
        mySidenav = "sidenav";        
        this.setState({mySidenav});
	}
	logOut(e){
		reactLocalStorage.set('isLoggedIn', 0);
		this.props.history.push('/auth/login')
	}
	
	
  render() {
  
  	let social_links = this.state.social_link_updated.length > 0
		&& this.state.social_link_updated.map((item, i) => {
		return (
			<p class="icon"><Link  target="blank" to={item.url}><img src={item.img} alt=""/></Link></p>
			
		)
	}, this);
	
	
  
	  
  
  	let custom_links = this.state.customLink.length > 0
		&& this.state.customLink.map((item, i) => {
		
				
		return (
		
			<div class="row custom_link_img">
				<div class="field">
					<span className="info-icon"><img style={{width: "100%"}} src={item.image}/></span>
					<p class="select">{item.name}</p>
					<span class="input">
						<div class="special-label"><Link target="blank" to={item.link} className=""><span className="errow"><img src={next} /></span></Link></div>
					</span>
				</div>
			</div>
		
		
			
		)
	}, this);
  
  	let phone_lists = this.state.phone_list.length > 0
		&& this.state.phone_list.map((item, i) => {
				let items = "tel:+"+item;
		return (
			<div className="info-box">
				<span className="info-icon"><img src={cell_phone}/></span>
				<p className="contacts"><Link to={items} className="">+{item}<span className="errow"><img src={next} /></span></Link></p>
			</div>
		)
	}, this);

	let email_lists = this.state.email_list.length > 0
		&& this.state.email_list.map((item, i) => {
				let emails = "mailto:"+item;
		return (
			<div className="info-box">
				<span className="info-icon"><img src={email} /></span>
				<p className="contacts"><Link to={emails} className="">{item} <span className="errow"><img src={next} /></span></Link></p>
			</div>
		)
	}, this);

	let website_lists = this.state.website_list.length > 0
		&& this.state.website_list.map((item, i) => {
		return (
			<div className="info-box">
				<span className="info-icon"><img src={website} /></span>
				<p className="contacts"><Link target="blank" to={item} className="">{item}<span className="errow"><img src={next}  /></span></Link></p>
			</div>
		)
	}, this);

	let address_lists = this.state.address_list.length > 0
		&& this.state.address_list.map((item, i) => {
			let map = 'https://www.google.com/maps?q='+this.state.location_list[i];
		return (
			<div className="info-box">
				<span className="info-icon"><img src={location} /></span>
				<p className="contacts"><Link target="blank" to={map} className="">{item}<span className="errow"><img src={next} /></span></Link></p>
			</div>
		)
	}, this);

    return (
    	<div className="profile-screen">
    	
	<div className="container">
		<header className="header">
			<div className="row">
				<div className="menu col-sm-4">
					<div id="mySidenav" className={this.state.mySidenav}>
						<div className="nav-sec">
							<div className="name-rol">
								<div className="acount-stng">
									<h2>
										{this.state.fields.first_name} <br/> {this.state.fields.last_name}
									</h2>
									<Link  className="link" to="/change-password"><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" color="var(--chakra-colors-brand-gray-300)" height="24" width="24" xmlns="http://www.w3.org/2000/svg" ><polyline points="9 18 15 12 9 6"></polyline></svg></Link>
									
								</div>
								<p className="email">{this.state.fields.email}</p>
							</div>
							<div className="menu-list">
								<Link target="_blank" rel="noopener" className="menu-link" to="https://api.vadoo.tv/landing_page?vid=1gvPq2r7wmvnh9S54pUowBdSKa5l7dix">How to videos</Link>
								<Link target="_blank" rel="noopener" className="menu-link" to="https://smarttap.world/contact-us/">Feedback</Link>
								<Link target="_blank" rel="noopener" className="menu-link" to="https://smarttap.world/contact-us/">Support</Link>
								<Link rel="noopener" className="menu-link" to="/change-password">Change Password</Link>
								<Link target="_blank" rel="noopener" className="menu-link" to="https://smarttap.world/for-teams/">Team Dashboard</Link>
							</div>
							<div className="log-out"  onClick={this.logOut.bind(this)}>
								<Link data-testid="log-out-button" className="css-td0fj9"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M16 13L16 11 7 11 7 8 2 12 7 16 7 13z"></path><path d="M20,3h-9C9.897,3,9,3.897,9,5v4h2V5h9v14h-9v-4H9v4c0,1.103,0.897,2,2,2h9c1.103,0,2-0.897,2-2V5C22,3.897,21.103,3,20,3z"></path></svg>Log Out</Link>
							</div>
						</div>
						<Link to="javascript:void(0)" className="closebtn" onClick={this.closeNav.bind(this)}>&times;</Link>
					</div>
					
					<div className="overlay"></div>
					<div className="menu-items">
						<span  className={this.state.dynamicshow} onClick={this.openNav.bind(this)}></span>
					</div>
				</div>
				<div className="menu col-sm-4">
					<div className="logo">
						<Link to="#"><img src={logo} alt="logo"/></Link>
					</div>
				</div>
				<div className="menu col-sm-4">
					<div className="qr-code">
						<Link to="/share-info"><img src={qr_code} alt="logo"/></Link>
					</div>
				</div>
				
			</div>
		</header>
		<div className="main-content">
			<div className="col-xs-12 name-hrdr">
				<h2>Hi {this.state.fields.first_name}<span className="orange">.</span></h2>
				<p>This is your profile where you can make changes anytime. Enjoy making new connections!</p>
			</div>
			
			<div className={this.state.outerColor}>
				<div className={this.state.innerColor}>
					<div className="col-md-4">
						<img src={this.state.fields.profile_pic} />
					</div>
					<div className="rol-sec">
						<h1>
							{this.state.fields.first_name}<br/>
							{this.state.fields.last_name}
						</h1>
						{this.state.fields.company ? (
						<p className="rol">
							{this.state.fields.job_title} <br/>
							 at {this.state.fields.company}
							 						</p>
						):(<p className="rol">
							{this.state.fields.job_title}</p>)}
					</div>
					<span className="edit-optn"><Link to="/edit-profile"><img src={edit} alt="edit"/></Link></span>
				</div>
				<div className="button-sec">
					<div className="action">
						<Link target="_blank" rel="noopener noreferrer" className="stap-link" to={this.state.fields.profile_link}><button type="button" className="stap-button" data-testid="public-view-button">Public View</button></Link>
						<Link to="/contact-list"><button type="button" className="stap-button" data-testid="profile-connections-button">Connections</button></Link>
					</div>
				</div>
				<div className="info-section">
					{this.state.fields.bio ? (
					<div className="about-sec">
						<div className="heder-sec row">
							<h3 className="sec-hdng">About</h3>
							<span className="edit"><Link to="/edit-bio">Edit</Link></span>
						</div>
						<p>{this.state.fields.bio}</p>
					</div>
					):(
					<div className="about-sec">
						<div className="heder-sec row">
							<h3 className="sec-hdng">About</h3>
						</div>
						<div className="links shadow-box">
							<p className="hdng"><span className="hd">You don’t have any bio yet</span></p>
							<p className="col-xs-12"><Link to="/edit-bio" className="link">Add</Link></p>
						</div>	
					</div>
					)}
					{social_links ? (
					<div className="social-sec">
						<div className="row heder-sec">
							<h3 className="sec-hdng">Social network</h3>
							<span className="edit"><Link to="/edit-social">Edit</Link></span>
						</div>
						<div className="socials">
						
							{social_links}
							
						</div>
					</div>
					):(
					<div className="about-sec">
						<div className="heder-sec row">
							<h3 className="sec-hdng">Social network</h3>
						</div>
						<div className="links shadow-box">
							<p className="hdng"><span className="hd">You don’t have any social network yet</span></p>
							<p className="col-xs-12"><Link to="/edit-social" className="link">Add</Link></p>
						</div>	
					</div>
					)}
					{custom_links ? (
					<div className="link-sec">
						<div className="row heder-sec">
							<h3 className="sec-hdng">Links</h3>
							<span className="edit"><Link to="/edit-link">Edit</Link></span>
						</div>
						{custom_links}
					</div>
					):(
					<div className="link-sec">
						<div className="row heder-sec">
							<h3 className="sec-hdng">Links</h3>
						</div>
						<div className="links shadow-box">
							<p className="hdng"><span className="hd">You don’t have any links yet</span></p>
							<p className="col-xs-12"><Link to="edit-link" className="link">Add</Link></p>
						</div>
					</div>
					)}
					
					{this.state.fields.video ? (
					<div className="video-sec">
						<div className="row heder-sec">
							<h3 className="sec-hdng">Video</h3>
							<span className="edit"><Link to="/edit-video">Edit</Link></span>
						</div>
						<iframe src={this.state.fields.video.video_url} allowfullscreen></iframe><p>{this.state.fields.video.video_desc}</p>
					</div> 
					):(
						 <div className="video-sec">
						<div className="row heder-sec">
							<h3 className="sec-hdng">Video</h3>
						</div>
						<div className="links shadow-box">
							<p className="hdng"><span className="hd">You don’t have any links yet</span></p>
							<p className="col-xs-12"><Link to="/edit-video" className="link">Add</Link></p>
						</div>
					</div> 
					)}
					{this.state.fields.contactinfo ? (
					<div className="contsct-sec">
						
						<div className="row heder-sec">
							<h3 className="sec-hdng">Contact info</h3>
							<span className="edit"><Link to="/edit-contact">Edit</Link></span>
						</div>
						
						{phone_lists}
						{email_lists}
						{website_lists}
						{address_lists}
					</div>
					):(
					<div className="video-sec">
						<div className="row heder-sec">
							<h3 className="sec-hdng">Contact info</h3>
						</div>
						<div className="links shadow-box">
							<p className="hdng"><span className="hd">You don’t have any Contact Info yet</span></p>
							<p className="col-xs-12"><Link to="/edit-contact" className="link">Add</Link></p>
						</div>
					</div> 
					)}
					<div className="analytics">
						<div className="row heder-sec">
							<h3 className="sec-hdng">Analytics</h3>
							<span className="all-time">All Times</span>
						</div>
						<div className="alltime-items">
							<div className="content">
								<div className="items">
									<span className="hdr">Views</span>
									<h4 className="velue-cstm">{this.state.fields.views}</h4>
								</div>
								<div className="items">
									<span className="hdr">Saves</span>
									<h4 className="velue-cstm">{this.state.fields.save}</h4>
								</div>
								<div className="items">
									<span className="hdr">Exchanges</span>
									<h4 className="velue-cstm">{this.state.fields.exchanges}</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
		
	</div>
</div>
    );
  }
}

export default Login;
