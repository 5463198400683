import { useState } from "react";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import fetch from 'isomorphic-fetch';
import API_BASE from '../constant';
import {reactLocalStorage} from 'reactjs-localstorage';
import  { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import helpers from '../../helper/helpers';
import queryString from  'query-string';

//Import Images
import logo from '../../assets/images/logo.svg';
import qr_code from '../../assets/images/qr-code.png';
import profile from '../../assets/images/profile.jpeg';
import edit from '../../assets/images/edit.png';
import Linkedin from '../../assets/images/Linkedin.svg';
import next from '../../assets/images/next.png';
import cell_phone from '../../assets/images/cell_phone.png';
import email from '../../assets/images/email.png';
import website from '../../assets/images/website.png';
import location from '../../assets/images/location.png';




class Update_link extends Component {
	constructor(props){
	
       super(props);
       this.state = {
           fields: {},
           errors: {},
           selectedImage: {},
           setSelectedImage: {},
           data: [],
           dynamiclass: ['head_err_msg'],
           succMsg: {},
    	   succClass: ['head_err_msg'],
    	   customLink:[],
		}
		if(!helpers.check_login()){
			this.props.history.push('/auth/login')
		}else{
			this.get_user_links();
		}
		

		
		
    }
    
    get_user_links() {
    	const values = queryString.parse(this.props.location.search);
		console.log(values);
		let id=0;
		if (typeof values.id !== "undefined") {
				let id=values.id;
				let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
				let user_id=sess_data.user_id
				let fields = this.state.fields;
				let type = 'get-custom-link' ;
				let LOGIN_API = API_BASE.concat(type);
				fetch(LOGIN_API, {
					method: 'POST',
					headers: new Headers({
	            		'Content-Type': 'application/x-www-form-urlencoded', 
					}),
					body : "user_id="+user_id+"&list_id="+id
				})
				.then((result) => {
			  		return result.json();
				}).then((res) => {
			
					if (res.status == 'True') {
						// fields['links'] = res.data;
						 //this.setState({fields});
						console.log(res.data[0].id);
						let fields = this.state.fields;
        				fields['title'] = res.data[0].name;
        				fields['linkurl'] = res.data[0].link;
        				fields['id'] = res.data[0].id;  
        				fields['newimage'] = res.data[0].image;     
        				this.setState({fields});
						
				
					}else {
						let errors={};
						errors['err']=res.msg;
						this.setState({errors: errors});
						const dynamiclass = 'alert alert-danger is_error';
           				this.setState({ dynamiclass });
           		
					}
				})
				
		}
    	
		return false;
	}
	urlPatternValidation = URL => {
	    const regex = new RegExp('(https?://)');    
	    return regex.test(URL);
	  };
    
   handleValidation(e){
    
    
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if(!fields["title"]){
           formIsValid = false;
           errors["title"] = "Title cannot be empty!";
        }
         if(!fields["linkurl"]){
           formIsValid = false;
           errors["linkurl"] = "URL cannot be empty!";
        }
	    const isTrueVal = !fields["linkurl"] || this.urlPatternValidation(fields["linkurl"]);
	    if(isTrueVal == false){
	    	formIsValid = false;
           	errors["linkurl"] = "Plese add valid url with https";
	    }

       this.setState({errors: errors});
       return formIsValid;
  }

  addlink(e) {
    
    let fields = this.state.fields;;
    let type = 'add-custom-link';
    let LOGIN_API = API_BASE.concat(type);
    let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
    let user_id=sess_data.user_id;
	const formData = new FormData();
	formData.append('user_id', user_id);
	formData.append('action', 'edit');
	formData.append('name', fields.title);
	formData.append('link', fields.linkurl);
	const values = queryString.parse(this.props.location.search);
	console.log(values);
	let list_id=0;
	if (typeof values.id !== "undefined") {
		let list_id=values.id;
		formData.append('link_id', list_id);
	}
	
	if (typeof this.state.fields.image !== "undefined") {

		formData.append(
        "image",
        this.state.fields.image,
        this.state.fields.image.name
      );
	}
	
	fetch(LOGIN_API, {
			body : formData,
			method: 'POST',
    
    })
      .then((result) => {
        return result.json();
    }).then((res) => {
      
      if (res.status == 'True') {
				let succMsg={};
				succMsg['msg']=res.msg;
				this.setState({succMsg: succMsg});
				const succClass = 'alert alert-success is_error';
		 	 	this.setState({ succClass })
		 	 	window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
				});
				setTimeout(() => {
				  this.props.history.push('/edit-link') 
				}, 2000);
				
      }else {
        let errors={};
        errors['err']=res.msg;
        this.setState({errors: errors});
        const dynamiclass = 'alert alert-danger is_error';
        this.setState({ dynamiclass });
        window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
				});
              
      }
    })
  }


  
  likSubmit(e){
        e.preventDefault();
        if(this.handleValidation(e)){
          const dynamiclass = 'head_err_msg';
           this.setState({ dynamiclass })
          this.addlink(e);
      }else{
        //const dynamiclass = 'alert alert-danger is_error';
          //this.setState({ dynamiclass });
      }

    }

    handleChange(field, e){         
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
    }
    onFileChange = e => {
      
		 if (e.target.files && e.target.files.length > 0) {
	      let fields = this.state.fields;
	        fields["newimage"] = URL.createObjectURL(e.target.files[0]);  
	        fields["image"] = e.target.files[0];           
	        this.setState({fields});
	    }
      
      
    
    };
	
	
	
  render() {
  
  
  
  
  	
    return (
    	      <div className="profile-screen">
	<div className="container">
		<header className="header">
			<div className="row">
				<div className="menu col-sm-12">
					<div className="menu-items">
						<Link to="/edit-link"><span className="icofont-arrow-left"></span></Link>
					</div>
				</div>
				
			</div>
		</header>
		<div className="main-content">
			<div className="col-xs-12 name-hrdr">
				<h2>Edit link<span className="orange">.</span></h2>
			</div>
			<div className="custom-links">
			</div>
				<form method="post" onSubmit= {this.likSubmit.bind(this)}>
				 <div className={this.state.succClass}>
					<ul>       
			            <li> {this.state.succMsg["msg"]} </li>
			                     
		            </ul>
	            </div>
				<div className="infof-section add-link-sec">
					
					<div className="form">
						<div className="field-sec">
							<input type="text" name="title" placeholder="Title" onChange={this.handleChange.bind(this, "title")} value={this.state.fields['title']}/>
							<div className='invalid-feedback text-start d-block'>
								{this.state.errors["title"]}  
							</div>
						</div>
						<div className="field-sec">
							<input type="text" name="linkurl" placeholder="Insert URL here" onChange={this.handleChange.bind(this, "linkurl")} value={this.state.fields['linkurl']}/>
							<div className='invalid-feedback text-start d-block'>
								{this.state.errors["linkurl"]}  
							</div>
						</div>
					</div>
					<div className="uload-sec">
						<div className="form">
							<input type="file" accept="image/*" onChange={this.onFileChange}/>
							<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="22" width="22" xmlns="http://www.w3.org/2000/svg"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
						</div>
						<p className="sec-hdng">Upload Thumbnail</p>
						<img
			              src={this.state.fields.newimage}
			            />
					</div>
					<div className="button-sec">
					<div className="action">
						<button type="submit" className="stap-button" data-testid="profile-connections-button">Save</button>
					</div>
				</div>
				
			</div>
			</form>
		</div>
		
	</div>
</div>


    );
  }
}

export default Update_link;
