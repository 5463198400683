import { useState } from "react";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import logo from '../../assets/images/logo.svg';
import fetch from 'isomorphic-fetch';
import API_BASE from '../constant';
import {reactLocalStorage} from 'reactjs-localstorage';
import  { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
const queryString = require('query-string');


class Login extends Component {
	constructor(props){
	
       super(props);
       this.state = {
           fields: {},
           errors: {},
           data: [],
           dynamiclass: ['head_err_msg'],
		}
		const values = queryString.parse(this.props.location.search);
	  if (typeof values.team_id !== "undefined") {
		  let fields =this.state.fields
		  fields['team_id'] = values.team_id;
		  fields['id'] = values.id;
		  this.setState({fields});
		  this.loginWithEmail();
		}else{
			this.props.history.push('/auth/login')
		}
		reactLocalStorage.set('isLoggedIn', 0);
		
    }

    handleValidation(e){
		
		
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Name
        if(!fields["password"]){
           formIsValid = false;
           errors["password"] = "Password cannot be empty!";
        }

        //Email
        if(!fields["email"]){
           formIsValid = false;
           errors["email"] = "Email id cannot be empty!";
        }
		
        if(e.target.email.value!=''){
           let lastAtPos = fields["email"].lastIndexOf('@');
           let lastDotPos = fields["email"].lastIndexOf('.');

           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
              formIsValid = false;
              errors["email"] = "Email is not valid!";
            }
		}  

       this.setState({errors: errors});
       return formIsValid;
	}
	loginWithEmail(e) {
		
		let fields = this.state.fields;
		let type = 'email-login' ;
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "id="+fields.id.trim()+"&team_id="+fields.team_id+"" 
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			
			if (res.status == 'True') {
				
				const login_session = {
					isLogged: 1,
					user_id:res.data.user_id,
					user_name:res.data.username,
					email:res.data.email,
					first_name:res.data.profiledata.first_name,
					last_name:res.data.profiledata.last_name,
					job_title:res.data.profiledata.job_title,
					company:res.data.profiledata.company,
					profile_pic:res.data.profiledata.profile_pic,
				}
				reactLocalStorage.set('isLoggedIn', 1);
				window.sessionStorage.setItem('login_session', JSON.stringify(login_session));
				this.props.history.push('/my-profile')
			}else {
				let errors={};
				errors['err']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           		this.setState({ dynamiclass });
           	window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
           		
			}
		})
	}

	login(e) {
		
		let fields = this.state.fields;
		let type = 'login' ;
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "email="+fields.email.trim()+"&password="+fields.password+"" 
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			
			if (res.status == 'True') {
				const login_session = {
					isLogged: 1,
					user_id:res.data.user_id,
					user_name:res.data.username,
					email:res.data.email,
					first_name:res.data.profiledata.first_name,
					last_name:res.data.profiledata.last_name,
					job_title:res.data.profiledata.job_title,
					company:res.data.profiledata.company,
					profile_pic:res.data.profiledata.profile_pic,
					
				}
				reactLocalStorage.set('isLoggedIn', 1);
				window.sessionStorage.setItem('login_session', JSON.stringify(login_session));
				this.props.history.push('/my-profile')
			}else {
				let errors={};
				errors['err']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           		this.setState({ dynamiclass });
           	window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
           		
			}
		})
	}
	
	contactSubmit(e){

        e.preventDefault();
        if(this.handleValidation(e)){
        	const dynamiclass = 'head_err_msg';
         	 this.setState({ dynamiclass })
	        this.login(e);
	    }else{
	      //const dynamiclass = 'alert alert-danger is_error';
          //this.setState({ dynamiclass });
	    }

    }

    handleChange(field, e){         
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
    }
	
	
	
	
  render() {
	
		
	
	const getYear = new Date().getFullYear();
    return (
    	<div className="login-screen">
	<div className="container">
		<header className="header">
			<figure><img src={logo} alt="logo" /></figure>
		</header>
		<div className="main-content">
			<h2>Log in to BOBCAT</h2>
			<div className="login-sec">
				<form method="POST" onSubmit= {this.contactSubmit.bind(this)} >
				
					<div className={this.state.dynamiclass}>
						<ul>       
		                     <li>  {this.state.errors["err"]}   </li>
		                     
	                	</ul>
                    </div> 
					<div role="group" className="stap-form-control">
						
						<div className="input__group">
							<input type="email"
                                        className={`stap-input form-control`}
                                        id="email"
                                        name="email"
                                        onChange={this.handleChange.bind(this, "email")}
							    		value= {this.state.fields['email']}
                                        placeholder="Email"
                                        
                                    />
							
						</div>
						 <div className='invalid-feedback text-start d-block'>
                             {this.state.errors["email"]}         
                        </div>
					</div>
					<div role="group" className="stap-form-control">
						
						<div className="input__group">
							 <input type='password'
                                            className={`stap-input form-control`}
                                            name="password"
                                            id="password"
                                            onChange={this.handleChange.bind(this, "password")}
							    			value={this.state.fields["password"]}
                                            placeholder="Password"
                                            
                                            
                                        />

                                   <span className="icofont-eye-altx"></span>
									<span className="icofont-eye-blockedx"></span>

                                       
						</div>
						<div className='invalid-feedback text-start d-block'>
                             {this.state.errors["password"]}         
                        </div>
					</div>
					<div className="frgt-pass">
						<Link to="/auth/forgot-password"><button type="button" className="stap-button" data-testid="forgot-password-button">Forgot Password?</button>
						</Link>
					</div>
					<div className="submit-btn"><button type="submit" className="button" data-testid="login-button">Log in</button></div>
				</form>
				<div className="noaccount"><span className="signup-link">No account? </span><a className="signup-button css-1m1uuvi" href="https://dev.smarttap.world/" target="_blank">Get your card</a></div>
			</div>
		</div>
		
	</div>
</div>
    );
  }
}

export default Login;
