import { useState } from "react";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import fetch from 'isomorphic-fetch';
import API_BASE from '../constant';
import {reactLocalStorage} from 'reactjs-localstorage';
import  { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import helpers from '../../helper/helpers';

//Import Images
import logo from '../../assets/images/logo.svg';
import logo2 from '../../assets/images/logo2.svg';
import loaderimg from '../../assets/images/smarttap_logo_gif.gif';
import qr_code from '../../assets/images/qr-code.png';
import profile from '../../assets/images/profile.jpeg';
import edit from '../../assets/images/edit.png';
import Linkedin from '../../assets/images/Linkedin.svg';
import next from '../../assets/images/next.png';
import cell_phone from '../../assets/images/cell_phone.png';
import email from '../../assets/images/email.png';
import website from '../../assets/images/website.png';
import location from '../../assets/images/location.png';
import loader from '../../assets/images/loader.gif';




class Login extends Component {
	constructor(props){
	
       super(props);
       this.state = {
           fields: {},
           errors: {},
           data: [],
           dynamiclass: ['head_err_msg'],
           showModel: ['modal fade head_err_msg'],
           loader: ['loader'],
           dynamicshow: ['icofont-navigation-menu show'],
           mySidenav: ['sidenav'],
           contactinfo:{},
           phone_list:[],
           email_list:[],
           website_list:[],
           address_list:[],
           location_list:[],
           customLink:[],
           social_link:[],
           social_link_updated:[],
           social_count:0,
           social_network: [],
           succMsg: {},
    	     succClass: ['head_err_msg'],
    	     innerColor:["row profile"],
           outerColor:["profile-sec"],
           exchangeText:["Exchange Contact"],
           disabled: false,
		

		}
		const values = window.location.href;
		const url = values.split('/'); 
		const profile_name = url[3];
		this.get_user_details(profile_name);
		/*let type = 'get-user-id' ;
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "profile_name="+profile_name
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			//this.get_user_details();
			if (res.status == 'True') {
				if (res.msg == 'redirect') {
					this.props.history.push('/'+res.profile_link)
				}
				let fields = this.state.fields;
				 fields['user_id'] = res.data;
				 this.setState({fields});
				this.get_user_details(res.data);
			}else {
				if (res.msg == 'register') {
					this.props.history.push('/auth/register?card_id='+profile_name)
				}else{
					this.props.history.push('/auth/login')
				}
				
           		
			}
		})*/
		
			
		let social_network = this.state.social_network;
		social_network['Select-Network']={key: 'Select_Network', value: 'Select Network',url:''}
		social_network['Anchor']={key: 'Anchor', value: 'Anchor',url:'https://anchor.fm/'}
		social_network['Apple_Podcast']={key: 'Apple_Podcast', value: 'Apple Podcast',url:'https://podcasts.apple.com/'}
		social_network['Calendly']={key: 'Calendly', value: 'Calendly',url:'https://calendly.com/'}
		social_network['Facebook']={key: 'Facebook', value: 'Facebook',url:'https://facebook.com/'}
		social_network['Github']={key: 'Github', value: 'Github',url:'https://github.com/'}
		social_network['Instagram']={key: 'Instagram', value: 'Instagram',url:'https://www.instagram.com/'}
		social_network['Linkedin']={key: 'Linkedin', value: 'Linkedin',url:'https://linkedin.com/in/'}
		social_network['Linkedin_company']={key: 'Linkedin_company', value: 'Linkedin company',url:'https://linkedin.com/company/'}
		social_network['Line']={key: 'Line', value: 'Line',url:'https://line.me/ti/p/'}
		social_network['Messenger']={key: 'Messenger', value: 'Messenger',url:'https://m.me/'}
		social_network['Metamask']={key: 'Metamask', value: 'Metamask',url:'https://metamask.app.link/send/'}
		social_network['Paypal']={key: 'Paypal', value: 'Paypal',url:'https://paypal.me/'}
		social_network['Snapchat']={key: 'Snapchat', value: 'Snapchat',url:'https://snapchat.com/add/'}
		social_network['Sound_Cloud']={key: 'Sound_Cloud', value: 'Sound Cloud',url:'https://soundcloud.com/'}
		social_network['Spotify']={key: 'Spotify', value: 'Spotify',url:'https://open.spotify.com/'}
		social_network['Tiktok']={key: 'Tiktok', value: 'Tiktok',url:'https://vm.tiktok.com/'}
		social_network['Twitch']={key: 'Twitch', value: 'Twitch',url:'https://www.twitch.tv/'}
		social_network['Twitter']={key: 'Twitter', value: 'Twitter',url:'https://twitter.com/'}
		social_network['Vimeo']={key: 'Vimeo', value: 'Vimeo',url:'https://venmo.com/'}
		social_network['Whatsapp']={key: 'Whatsapp', value: 'Whatsapp',url:'https://wa.me/'}
		social_network['Youtube']={key: 'Youtube', value: 'Youtube',url:'https://youtube.com/'}
		this.setState({social_network});
		
			
    }

    get_user_details(user_id) {
		let innerColor = this.state.innerColor;
		let outerColor = this.state.outerColor;
		let fields = this.state.fields;
		let type = 'profile-data' ;
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "user_id="+user_id+"&action=profilename"
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			if (res.status == 'True') {
				 window.history.pushState('', 'STAP', res.data.profiledata.profile_link);
				 let loader = this.state.loader;
				 loader = "loader head_err_msg"
				 this.setState({loader});
				 fields['bio'] = res.data.profiledata.bio;
				 fields['user_id'] = res.data.user_id;
				 fields['first_name'] = res.data.profiledata.first_name;
				 fields['last_name'] = res.data.profiledata.last_name;
				 fields['job_title'] = res.data.profiledata.job_title;
				 fields['company'] = res.data.profiledata.company;
				 fields['profile_link'] = res.data.profiledata.profile_link;
				 fields['profile_pic'] = res.data.profiledata.profile_pic;
				 if (res.data.profiledata.innerColor == '' || res.data.profiledata.innerColor == null) {
				 	innerColor ="row profile";
				 }else{
				 	innerColor = res.data.profiledata.innerColor; 
				 }
				 if (res.data.profiledata.outerColor == '' || res.data.profiledata.outerColor == null) {
				 	outerColor ="profile-sec";
				 }else{
				 	outerColor = res.data.profiledata.outerColor; 
				 }
				 this.setState({innerColor});
				 this.setState({outerColor});
				 fields['social_link'] = res.data.social_link;
				 fields['customlink'] = res.data.customlink;
				 fields['video'] = res.data.video;
				 fields['contactinfo'] = res.data.contactinfo;
				 fields['analytics'] = res.data.analytics;
				 let customLink=this.state.customLink;
				 let social_link=this.state.social_link;
				 social_link=JSON.parse(res.data.social_link.social_link);
				 let social_count=this.state.social_count;
				 social_count=res.data.social_link.social_count;
				 customLink=res.data.customlink;
				 this.setState({customLink});
				 this.setState({social_link});
				 this.setState({social_count});
				 
				  
				
     			 let updated_social_link=[];
				 let idx=0;
				 let social_network = this.state.social_network;
				 if(social_count){
		             for (var j = 0; j <= social_count; j++) {
	     	       		if(j!=i){
	     	       			let val1="social_media_"+j;
	     	       			let val2="social_text_"+j;
	     		        	let name=social_link[val1];
	     		        	let text_val=social_link[val2];
	     		        	let img="https://dev.smarttap.world/wp-content/uploads/2022/01/"+name+".png";
	     		        	let re_url=social_network[name].url+text_val;
	     		        	
	     		        	//alert(re_url)
	     		        	
	     		        	updated_social_link[j]={'img':img,'url':re_url}
	     		        	//console.log(this.state.social_link)
	     		        	 this.setState({social_link_updated:updated_social_link});
	     		        	
	        	        	idx++;
	     	        	}	
		 			}
		 		}
				  
				  
				 if(res.data.contactinfo.phone_count == 0 && res.data.contactinfo.email_count == 0 && res.data.contactinfo.website_count == 0 && res.data.contactinfo.location_count == 0){
				 	fields['contactinfo'] = '';
				 }
				 let contactinfo = this.state.contactinfo;
				 if(res.data.contactinfo.phone_data){
					  contactinfo['phone_data']=JSON.parse(res.data.contactinfo.phone_data);
				 }
				 if(res.data.contactinfo.email_data){
					  contactinfo['email_data']=JSON.parse(res.data.contactinfo.email_data);
				 }
				 if(res.data.contactinfo.website_data){
					  contactinfo['website_data']=JSON.parse(res.data.contactinfo.website_data);
				 }
				 if(res.data.contactinfo.location_data){
					  contactinfo['address_data']=JSON.parse(res.data.contactinfo.location_data);
				 }
				 this.setState({contactinfo});
				 let phone_list = this.state.phone_list
				 for (var i = 0; i < this.state.fields.contactinfo.phone_count; i++) {
			       let contact = this.state.contactinfo.phone_data['contact_'+i];
			       phone_list.push(contact);
			   	 } 
			     this.setState(phone_list);
			     let email_list = this.state.email_list
				 for (var i = 0; i < this.state.fields.contactinfo.email_count; i++) {
			       let email = this.state.contactinfo.email_data['email_'+i];
			       email_list.push(email);
			   	 } 
			     this.setState(email_list);

			     let website_list = this.state.website_list
				 for (var i = 0; i < this.state.fields.contactinfo.website_count; i++) {
			       let website = this.state.contactinfo.website_data['website_'+i];
			       website_list.push(website);
			   	 } 
			     this.setState(website_list);

			     let address_list = this.state.address_list
			     let location_list = this.state.location_list
				 for (var i = 0; i < this.state.fields.contactinfo.location_count; i++) {
			        let area2 = this.state.contactinfo.address_data['area2_'+i];
				 	if (!this.state.contactinfo.address_data['area2_'+i]) {
				 		area2 = '';
				 	}else{
				 		area2 = this.state.contactinfo.address_data['area2_'+i]
				 	}
			        let location = this.state.contactinfo.address_data['address_type_'+i]+': '+this.state.contactinfo.address_data['area1_'+i]+' '+area2+' '+this.state.contactinfo.address_data['city_'+i]+' '+this.state.contactinfo.address_data['state_'+i]+' '+this.state.contactinfo.address_data['pcode_'+i]+' '+this.state.contactinfo.address_data['country_'+i];
			        let onlylocation = this.state.contactinfo.address_data['area1_'+i]+' '+area2+' '+this.state.contactinfo.address_data['city_'+i]+' '+this.state.contactinfo.address_data['state_'+i]+' '+this.state.contactinfo.address_data['pcode_'+i]+' '+this.state.contactinfo.address_data['country_'+i];
			    	location_list.push(onlylocation);
			        address_list.push(location);
			   	 } 
			     this.setState(location_list);
			     this.setState(address_list);
     	
			}else{
				const values = window.location.href;
				const url = values.split('/'); 
				const profile_name = url[3];
				let type = 'get-user-id' ;
				let LOGIN_API = API_BASE.concat(type);
				fetch(LOGIN_API, {
					method: 'POST',
					headers: new Headers({
			            'Content-Type': 'application/x-www-form-urlencoded', 
					}),
					body : "profile_name="+profile_name
					})
					.then((result) => {
					  return result.json();
				}).then((res) => {
					//this.get_user_details();
					if (res.status == 'True') {
						if (res.msg == 'redirect') {
							this.props.history.push('/'+res.profile_link)
						}
						let fields = this.state.fields;
						 fields['user_id'] = res.data;
						 this.setState({fields});
						this.get_user_details(res.data);
					}else {
						if (res.msg == 'register') {
							this.props.history.push('/auth/register?card_id='+profile_name)
						}else{
							this.props.history.push('/auth/login')
						}
						
		           		
					}
				})
			}
		})
		return false;
	}
	showPupup(e){
		let showModel = this.state.showModel;
        showModel = "modal fade showmyModal";        
        this.setState({showModel});
	}	
	closePupup(e){
		let showModel = this.state.showModel;
        showModel = "modal fade";        
        this.setState({showModel});
        const succClass = 'head_err_msg';
		this.setState({ succClass })
		let fields = this.state.fields;
		fields['name'] = '';
		fields['email'] = '';
		fields['phone'] = '';
		this.setState({ fields })
	}
	handleChange(field, e){         
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
    }
	handleValidation(e){
		
		
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if(!fields["name"]){
           formIsValid = false;
           errors["name"] = "Name cannot be empty!";
        }
        if(!fields["email"]){
           formIsValid = false;
           errors["email"] = "Email cannot be empty!";
        }
        if(!fields["comp"]){
           formIsValid = false;
           errors["comp"] = "Company cannot be empty!";
        }
        if(!fields["we_met"]){
           formIsValid = false;
           errors["we_met"] = "We met at cannot be empty!";
        }
        if(!fields["phone"]){
           formIsValid = false;
           errors["phone"] = "Phone Number cannot be empty!";
        }
       this.setState({errors: errors});
       return formIsValid;
	}
	exchangeSubmit(e){
        e.preventDefault();
        if(this.handleValidation(e)){
        	const dynamiclass = 'head_err_msg';
         	 this.setState({ dynamiclass })
	        this.exchange(e);
	    }else{
	      //const dynamiclass = 'alert alert-danger is_error';
          //this.setState({ dynamiclass });
	    }

    }
    exchange(e) {
		
		let fields = this.state.fields;
		let exchangeText = this.state.exchangeText;
		exchangeText = 'Please Wait';
		this.setState({ exchangeText })
		let disabled = this.state.disabled;
		disabled = true;
		this.setState({ disabled })
		let type = 'add-user-contact' ;
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "name="+fields.name+"&email="+fields.email+"&comp="+fields.comp+"&we_met="+fields.we_met+"&phone="+fields.phone+"&user_id="+fields.user_id+""
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			let exchangeText = this.state.exchangeText;
			exchangeText = 'Exchange Contact';
			this.setState({ exchangeText })
			let disabled = this.state.disabled;
			disabled = false;
			this.setState({ disabled })
			if (res.status == 'True') {
				let succMsg={};
				succMsg['msg']=res.msg;
				this.setState({succMsg: succMsg});
				const succClass = 'alert alert-success is_error';
		 	 	this.setState({ succClass })
		 	 	window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
		 	 	setTimeout(() => {
				 let showModel = this.state.showModel;
		        showModel = "modal fade";        
		        this.setState({showModel});
		        const succClass = 'head_err_msg';
				this.setState({ succClass })
				let fields = this.state.fields;
				fields['name'] = '';
				fields['email'] = '';
				fields['phone'] = '';
				this.setState({ fields })
				}, 2000);
			}else {
				let errors={};
				errors['err']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           		this.setState({ dynamiclass });
           		window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
           		
			}
		})
	}
	
  render() {
  //console.log('lomks')
  	//console.log(this.state.social_link)
  
  	let social_links = this.state.social_link_updated.length > 0
		&& this.state.social_link_updated.map((item, i) => {
		return (
			<p className="icon"><Link target="blank" to={item.url}><img src={item.img} alt=""/></Link></p>
			
		)
	}, this);
	
	
  
	  
  
  	let custom_links = this.state.customLink.length > 0
		&& this.state.customLink.map((item, i) => {
		//console.log(item.image)
		
				
		return (
			
			<div className="row custom_link_img">
				<div className="field">
					<span className="info-icon"><img style={{width: "100%"}} src={item.image}/></span>
					<p className="select">{item.name}</p>
					<span className="input">
						<div className="special-label"><Link target="blank" to={item.link} className=""><span className="errow"><img src={next} /></span></Link></div>
					</span>
				</div>
			</div>
		
		
			
		)
	}, this);
  
  	let phone_lists = this.state.phone_list.length > 0
		&& this.state.phone_list.map((item, i) => {
				let items = "tel:+"+item;
		return (
			<div className="info-box">
				<span className="info-icon"><img src={cell_phone}/></span>
				<p className="contacts"><Link to={items} className="">+{item}<span className="errow"><img src={next} /></span></Link></p>
			</div>
		)
	}, this);

	let email_lists = this.state.email_list.length > 0
		&& this.state.email_list.map((item, i) => {
				let emails = "mailto:"+item;
		return (
			<div className="info-box">
				<span className="info-icon"><img src={email} /></span>
				<p className="contacts"><Link to={emails} className="">{item} <span className="errow"><img src={next} /></span></Link></p>
			</div>
		)
	}, this);

	let website_lists = this.state.website_list.length > 0
		&& this.state.website_list.map((item, i) => {
		return (
			<div className="info-box">
				<span className="info-icon"><img src={website} /></span>
				<p className="contacts"><Link target="blank" to={item} className="">{item}<span className="errow"><img src={next}  /></span></Link></p>
			</div>
		)
	}, this);

	let address_lists = this.state.address_list.length > 0
		&& this.state.address_list.map((item, i) => {
			let map = 'https://www.google.com/maps?q='+this.state.location_list[i];
		return (
			<div className="info-box">
				<span className="info-icon"><img src={location} /></span>
				<p className="contacts"><Link target="blank" to={map} className="">{item}<span className="errow"><img src={next} /></span></Link></p>
			</div>
		)
	}, this);
	let bio = this.state.fields.bio;
	const return_bio = () => {
      if (bio) {
        return <div className="about-sec">
						<div className="heder-sec row">
							<h3 className="sec-hdng">About</h3>
						</div>
						<p>{this.state.fields.bio}</p>
					</div>
      } 
    }
    let savecontact = "https://dev.smarttap.world/vcard/?user_id="+this.state.fields.user_id;
    return (
    	<div className="profile-screen">
    	<div className={this.state.loader}>
			<img src={loaderimg} />
		</div>
	<div className="container">
		<header className="header public_hed">
			<div className="row">
				
				
			</div>
		</header>
		<div className="main-content">
			
			
			<div id="profile2" className={this.state.outerColor}>
				<div className="row profile-hd">
					<div className="logo col-md-6">
						<Link to="#"><img src={logo2} alt="logo"/></Link>
					</div>
					<div className="button col-md-6">
						<Link to="https://stap.digital">Get Your Card</Link>
					</div>
				</div>
				<div className={this.state.innerColor}>
					<div className="col-md-6 profile-pic">
						<img src={this.state.fields.profile_pic} />
					</div>
					<div className="rol-sec col-md-6">
						<h1>
							{this.state.fields.first_name}<br/>
							{this.state.fields.last_name}
						</h1>
						{this.state.fields.company ? (
						<p className="rol">
							{this.state.fields.job_title} <br/>
							 at {this.state.fields.company}
							 						</p>
						):(<p className="rol">
							{this.state.fields.job_title}</p>)}

					</div>
				</div>
				<div className="button-sec">
					<div className="action">
						<Link rel="noopener noreferrer" className="stap-link" to={savecontact}><button type="button" className="stap-button" data-testid="public-view-button">Save Contact</button></Link>
						<Link to="#"  onClick={this.showPupup.bind(this)}><button type="button" className="stap-button exchange" data-testid="profile-connections-button">Exchange Contact</button></Link>
						  <div className={this.state.showModel} id="myModal" role="dialog">
							<div className="modal-dialog">
							  <div className="modal-content">
								<div class="modal-header">
								  <h4 class="modal-title">Share your contact with {this.state.fields.first_name}</h4>
								  <button onClick={this.closePupup.bind(this)} type="button" class="close" data-dismiss="modal">×</button>
								</div>
								<form method="post" onSubmit= {this.exchangeSubmit.bind(this)}>
								<div className="modal-body">
									<div className={this.state.succClass}>
										<ul>       
								            <li> {this.state.succMsg["msg"]} </li>
								                     
							            </ul>
					            	</div>
									<div className="field">
										<span className="input">
											<input className="form-control" placeholder="Full Name" type="text" onChange={this.handleChange.bind(this, "name")} value={this.state.fields['name']}/>
											<div className='invalid-feedback text-start d-block'>
				                             	{this.state.errors["name"]}         
				                        	</div>
										</span>
										<span className="input">
											<input className="form-control" placeholder="Email" type="email" onChange={this.handleChange.bind(this, "email")} value={this.state.fields['email']}/>
											<div className='invalid-feedback text-start d-block'>
				                             	{this.state.errors["email"]}         
				                        	</div>
										</span>
										<span className="input">
											<input className="form-control" placeholder="Company" type="text" onChange={this.handleChange.bind(this, "comp")} value={this.state.fields['comp']}/>
											<div className='invalid-feedback text-start d-block'>
				                             	{this.state.errors["comp"]}         
				                        	</div>
										</span>
										<span className="input">
											<input className="form-control" placeholder="We met at" type="text" onChange={this.handleChange.bind(this, "we_met")} value={this.state.fields['we_met']}/>
											<div className='invalid-feedback text-start d-block'>
				                             	{this.state.errors["we_met"]}         
				                        	</div>
										</span>
										<span className="input">
											<input className="form-control" placeholder="Phone Number" type="number" onChange={this.handleChange.bind(this, "phone")} value={this.state.fields['phone']}/>
											<div className='invalid-feedback text-start d-block'>
				                             	{this.state.errors["phone"]}         
				                        	</div>
										</span>
										<p>You’ll receive {this.state.fields.first_name}'s contact on the above email address</p>
									</div>
								</div>
								<div className="modal-footer">
								  <button type="submit" disabled={this.state.disabled} className="btn btn-default" data-testid="exchange-contact-submit-button">{this.state.exchangeText}</button>
								</div>
								</form>
							  </div>
							  
							</div>
						  </div>
					</div>
				</div>
				<div className="info-section">
					{return_bio()}
					{social_links ? (
					
					<div className="social-sec">
						<div className="row heder-sec">
							<h3 className="sec-hdng">Social network</h3>
						</div>
						<div className="socials">
								{social_links}
						</div>
					</div>
					):(<p></p>)}
					{custom_links ? (
					<div className="link-sec">
						<div className="row heder-sec">
							<h3 className="sec-hdng">Links</h3>
						</div>
						{custom_links}	
					</div>
					):(<p></p>)}
					
					{this.state.fields.video ? (
					<div className="video-sec">
						<div className="row heder-sec">
							<h3 className="sec-hdng">Video</h3>
						</div>
						<iframe src={this.state.fields.video.video_url} allowfullscreen></iframe><p>{this.state.fields.video.video_desc}</p>
					</div> 
					):(<p></p>
					)}
					{this.state.fields.contactinfo ? (
					<div className="contsct-sec">
						
						<div className="row heder-sec">
							<h3 className="sec-hdng">Contact info</h3>
						</div>
						
						{phone_lists}
						{email_lists}
						{website_lists}
						{address_lists}
					</div>
					):(<p></p>
					)}
				</div>
				
			</div>
		</div>
		
	</div>
</div>
    );
  }
}

export default Login;
